@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@layer components {
    .btn {
        @apply flex justify-center w-32 drop-shadow-xl rounded-lg m-2 p-2 items-center text-center
    }
    .btn-white {
        @apply bg-white text-blue
    }
    .btn-white-hover {
        @apply bg-gray-400
    }
    .seedlingMd {
        @apply w-64 h-64 text-white -rotate-12
    }
    .seedling {
        @apply w-64 h-64 text-white/25 -rotate-12
    }
    input {
        @apply rounded-xl m-2 p-2 border border-gray-500
    }
    input {
        @apply text-blue
    }
    .input {
        @apply bg-grey text-black
    }
    .input-placeholder {
        @apply text-slate-500
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}